<template>
  <v-list class="scrollmenu ml-2 pb-0 mb-0 mt-4 d-flex d-lg-none" ref="menuContainer">
    <v-list-item ref="dashboardLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/dashboard' }" @click="$router.push({ path: '/dashboard' })">
        <button class="mobile-menu-item start-icon mr-3"></button>
        {{ $t('start') }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item ref="addSettlementLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/newDocument' }" @click="$router.push({ path: '/newDocument' })">
        <button class="mobile-menu-item plus-icon mr-3"></button>
        {{ $t('add_settlement') }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item ref="documentsLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/documents' }" @click="$router.push({ path: '/documents' })">
        <button class="mobile-menu-item documents-icon mr-3"></button>
        {{ $t('my_cases') }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item ref="messagesLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/messages' }" @click="$router.push({ path: '/messages' })">
        <button class="mobile-menu-item messages-icon mr-3"></button>
        {{ $t('messages') }}
        <span class="messages-square-sidebar ml-2">{{ unreadMessagesSize }}</span>
      </v-list-item-title>
    </v-list-item>
    <!-- <v-list-item ref="recommendLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/#' }" @click="$router.push({ path: '#' })">
        <button class="mobile-menu-item share-icon mr-3"></button>
        {{ $t('recommend_and_earn') }}
      </v-list-item-title>
    </v-list-item> -->
    <!-- <v-list-item ref="settingsLink">
      <v-list-item-title :class="{ 'active-link': $route.path === '/#' }" @click="$router.push({ path: '#' })">
        <button class="mobile-menu-item settings-icon mr-3"></button>
        {{ $t('settings') }}
      </v-list-item-title>
    </v-list-item> -->
  </v-list>
</template>

<script>

export default {
  name: 'horizontalMenu',
  mounted () {
    let targetElement = null
    if (this.$route.path === '/dashboard') {
      targetElement = this.$refs.dashboardLink.$el
    } else if (this.$route.path === '/documents') {
      targetElement = this.$refs.documentsLink.$el
    } else if (this.$route.path === '/messages') {
      targetElement = this.$refs.messagesLink.$el
    } else if (this.$route.path === '/#') {
      targetElement = this.$refs.addSettlementLink.$el
    }

    if (targetElement) {
      const targetLeft = targetElement.getBoundingClientRect().left
      const menuWidth = this.$refs.menuContainer.$el.offsetWidth
      const scrollOffset = targetLeft - (menuWidth / 2) + 150

      this.$refs.menuContainer.$el.scrollLeft = scrollOffset
    }
  }
}
</script>

<style lang="scss">
  div.scrollmenu {
    background-color: #333;
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  div.scrollmenu::-webkit-scrollbar {
    display: none;
  }

  div.scrollmenu div {
    display: inline-block;
    text-align: center;
    padding: 7px;
    text-decoration: none;

    .v-list-item__title {
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
      border-radius: 10px;
      padding: 8px 16px;
      align-items: center;
      gap: 16px;

      &.active-link {
        background: linear-gradient(270deg, #D20F6E 0%, #F57921  101.54%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .documents-icon {
          background: transparent url('../../assets/icons/documents-gradient.svg') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }

        .messages-icon {
          background: transparent url('../../assets/icons/envelope-gradient.svg') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }

        .start-icon {
          background: transparent url('../../assets/icons/start-gradient.svg') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }

        .plus-icon {
          background: transparent url('../../assets/icons/plus-gradient.png') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }

        .share-icon {
          background: transparent url('../../assets/icons/share-gradient.png') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }

        .settings-icon {
          background: transparent url('../../assets/icons/settings-gradient.png') 0% 0% no-repeat padding-box;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
</style>
