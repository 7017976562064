<template>
  <div>
    <!-- HERO START -->
    <!-- <missingDocuments></missingDocuments> -->
    <!-- HERO END -->
    <horizontalMenu></horizontalMenu>
    <!-- MY DOCUMENTS START -->
    <v-card
      class="mx-auto card-dashboard-container mt-3 pt-3"
    >
      <div class="row pa-3">
        <button class="documents-icon mr-4"></button>
        {{ $t('my_cases') }}
      </div>
      <div class="text-center" v-if="documentsLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-carousel
        v-if="!documentsLoading && documents.length > 0"
        :value="carouselValue"
        @input="onCarouselChange"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        :continuous="false"
        :show-arrows="false"
        hide-delimiter-background
      >
        <v-carousel-item class="mt-3" v-for="(document) in documents.slice(0, 3)" :key="document.id">
          <v-sheet
            color="white"
            height="70%">
            <div class="row d-flex d-lg-none mt-0 pa-0">
              <v-col cols="9">
                <h4 class="year_h4">
                  <span>{{document.attributes['year'] }}</span>
                  <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
                </h4>
              </v-col>
              <v-col cols="3" class="text-right">
                <div class="symbol_div">{{document.attributes['symbol'] }}</div>
              </v-col>
            </div>
            <div class="row pa-0 pr-2 mt-0">
              <v-col cols="12" lg="5" class="document-status pt-0">
                <div v-if="document.attributes['status'] &&  document.attributes.current_step">
                  <div class="gradient-pink mb-4 semibold-font">{{ document.attributes['status'] }}</div>
                  <h5 class="document-current-step-description-small">{{ $t('settlement_stage_uppercase') }}:</h5>
                  <div class="document-current-step-description-small">{{ document.attributes.current_step['step_name'] }}</div>
                  <div class="document-current-step-description-small mt-3">{{ document.attributes.current_step['step_description'] }}</div>
                </div>
              </v-col>
            </div>
            <div class="see-more mt-6 text-right">
              <router-link class="see-more" :to="'/documents/' + document.id + '/general'">
                {{ $t('find_out_more') }}
                <v-icon
                  small
                  class="ml-2 arrow-icon"
                >
                  fas fa-arrow-right
                </v-icon>
              </router-link>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <!-- MY DOCUMENTS END -->
    <!-- MESSAGES START -->
    <v-card
      class="mx-auto card-dashboard-container mt-6 pt-3"
    >
      <div class="row pa-3">
        <button class="messages-icon mr-4"></button>
        {{ $t('messages') }}
      </div>
      <div class="text-center" v-if="messagesLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!messagesLoading">
        <div v-for="(message) in messages.slice(0, 1)" :key="message.id">
          <div class="row d-flex d-lg-none mt-0 pa-0">
            <v-col cols="9">
              <h4 class="year_h4">
                <span>{{ message.attributes['title'] }}</span>
              </h4>
            </v-col>
            <v-col cols="3" class="text-right">
              <div class="symbol_div">{{ $date(message.attributes['created_at']).format('DD.MM.YYYY') }}</div>
            </v-col>
          </div>
          <div class="row pa-0 pr-2 mt-3">
            <v-col cols="12" lg="5" class="document-status pt-0">
              <div class="document-current-step-description-small">{{ message.attributes['message'] }}</div>
            </v-col>
          </div>
          <div class="see-more mt-6 mb-2 text-right">
            <router-link class="see-more" :to="'/messages'">
              {{ $t('read_more') }}
              <v-icon
                small
                class="ml-2 arrow-icon"
              >
                fas fa-arrow-right
              </v-icon>
            </router-link>
          </div>
        </div>
      </div>
    </v-card>
    <!-- MESSAGES END -->
    <!-- RECOMMEND START -->
    <!-- <v-card
      class="mx-auto card-dashboard-container recommend-container mt-6 pt-3"
    >
      <div class="row pa-3">
        <button class="share-icon mr-4"></button>
        {{ $t('recommend_and_earn') }}
      </div>
      <div class="row pa-0 pr-2 mt-2">
        <v-col cols="12" lg="5" class="document-status pt-0 text-right">
          <div class="gradient-pink mb-4 semibold-font">{{ $t('earn_money') }}<br>{{ $t('in_home') }}<br></div>
          <div class="document-current-step-description-small text-right">{{ $t('recommend_company') }}<br>{{ $t('and_get_money') }}</div>
        </v-col>
      </div>
      <div class="see-more mt-6 mb-2 text-right">
        <router-link class="see-more" :to="'#'">
          {{ $t('more') }}
          <v-icon
            small
            class="ml-2 arrow-icon"
          >
            fas fa-arrow-right
          </v-icon>
        </router-link>
      </div>
    </v-card> -->
    <!-- RECOMMEND END -->
    <div class="row pa-0 pr-2 mt-6 ml-4 mr-2 mb-2">
      <!-- <v-col cols="6" lg="5" class="mb-4">
        <div class="pr-1">
          <div class="row button-white-small">
            <router-link :to="'#'">
              <button class="chat-icon mr-0"></button>
              <div>{{ $t('livechat') }}</div>
            </router-link>
          </div>
        </div>
      </v-col> -->
      <v-col cols="6" lg="5" class="mb-4">
        <div class="pl-1">
          <div class="row button-white-small">
            <router-link :to="'/contact'">
              <div class="row d-sm-none">
                <v-col cols="12" class="text-center">
                  <button class="phone2-icon mr-0"></button>
                </v-col>
                <v-col cols="12" class="text-center pt-0">
                  <div>{{ $t('contact') }}</div>
                </v-col>
              </div>

              <div class="d-none d-sm-flex">
                <button class="phone2-icon mr-4"></button>
                <div>{{ $t('contact') }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </v-col>
      <v-col cols="6" lg="5" class="mb-4">
        <div class="pl-1">
          <div class="row button-white-small">
            <router-link :to="'/account'">
              <div class="row d-sm-none">
                <v-col cols="12" class="text-center">
                  <button class="person-data-icon mr-0"></button>
                </v-col>
                <v-col cols="12" class="text-center pt-0">
                  <div>{{ $t('your_data') }}</div>
                </v-col>
              </div>

              <div class="d-none d-sm-flex">
                <button class="person-data-icon mr-4"></button>
                <div>{{ $t('your_data') }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </v-col>
      <!-- <v-col cols="6" lg="5" class="mb-4">
        <div class="pl-1">
          <div class="row button-white-small">
            <router-link :to="'#'">
              <button class="settings-icon mr-0"></button>
              <div>{{ $t('settings') }}</div>
            </router-link>
          </div>
        </div>
      </v-col> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import missingDocuments from '../components/banners/missingDocuments.vue'
import HorizontalMenu from '../components/navigation/horizontalMenu.vue'
import { getIsExpoApp } from 'cuf-expo-bridge'

export default {
  name: 'Dashboard',
  data () {
    return {
      carouselValue: 0,
      touchStartX: 0,
      touchEndX: 0
    }
  },
  components: {
    // missingDocuments,
    HorizontalMenu
  },
  computed: {
    ...mapGetters(['currentClient']),
    ...mapGetters(['documents']),
    ...mapGetters(['documentsLoading']),
    ...mapGetters(['messages']),
    ...mapGetters(['messagesLoading'])
  },
  created () {
    this.$store.dispatch('getDocuments')
    this.$store.dispatch('getMessages')

    if (getIsExpoApp() && this.currentClient && !this.currentClient.installed_app) {
      this.$http.post(`/client_accounts/${this.currentClient.id}/installed_app/`)
        .then(response => {
          this.$store.dispatch('setInstalledApp')
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  methods: {
    onCarouselChange (newValue) {
      this.carouselValue = newValue
    },
    onTouchStart (event) {
      this.touchStartX = event.touches[0].clientX
    },
    onTouchMove (event) {
      const deltaX = event.touches[0].clientX - this.touchStartX
      this.carouselValue += deltaX
      this.touchStartX = event.touches[0].clientX
    },
    onTouchEnd (event) {
      this.touchEndX = event.changedTouches[0].clientX
      if (this.touchEndX < this.touchStartX) {
        this.carouselValue += 1
      } else if (this.touchEndX > this.touchStartX) {
        this.carouselValue -= 1
      }
    }
  }
}
</script>

<style lang="scss">
  .card-dashboard-container {
    max-width: 90vw;
    background-color: white;
    border-radius: 10px !important;
    opacity: 1;
    padding: 12px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20) !important;
  }

  .text-orange {
    color: #F26822;
  }

  .document-current-step-description-small {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-carousel__item, .v-carousel--hide-delimiter-background {
    height: 430px !important;
  }

  .v-carousel__controls__item {
    margin: 0 !important;

  }

  .v-carousel__controls__item.theme--dark.v-btn.v-btn--icon {
    color: #8B8B8B;
  }

  .v-carousel__controls__item.v-btn--icon.v-size--small .v-icon {
    font-size: 8px !important;
    color: currentColor;
    opacity: 1;
    margin: 0px !important;
  }

  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active {
    background: none !important;
    color: #4E4E4E !important;
  }

  #app > div > div > div > div > main > div > div > div > div > div.v-window.v-item-group.theme--dark.v-carousel.v-carousel--hide-delimiter-background > div.v-carousel__controls > div > button.v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn--icon.v-btn--round.theme--dark.v-size--small > span > i {
    font-size: 12px !important;
    color: currentColor;
    opacity: 1;
  }

  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-icon {
    background: none !important;
    color: #4E4E4E !important;
    font-size: 10px !important;
  }

  .v-carousel__controls__item.theme--dark.v-btn--active::before {
    content: none !important;
  }

  .see-more {
    color: #d2116e !important;
    text-decoration: none;
    font-size: 12px;

    .arrow-icon {
      color: #d2116e !important;
    }
  }

  .see-more:hover {
    color: rgba(0, 0, 0, 0.54) !important;

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .button-white-small {
    border-radius: 10px;
    background: var(--white, #FFF);

    /* box-shadow */
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);

    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    a {
      text-decoration: none;
      color: black;
      display: contents;
    }
  }

  div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.recommend-container {
    background: transparent url('../assets/piz.png') 0% 0% no-repeat padding-box;
    background-position: left 22px;
  }
</style>
